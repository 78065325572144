<template>
  <div class="account-container">
    <a-form-model class="form" :model="accountForm" labelAlign="left" ref="accountForm" v-bind="layout">
      <a-form-model-item label="注册邮箱">
        <a-input v-model.trim="accountForm.loginId" disabled />
      </a-form-model-item>
      <a-form-model-item label="角色">
        <a-input v-model.trim="accountForm.rolesName" disabled />
      </a-form-model-item>
      <a-form-model-item
        label="账号名称"
        prop="name"
        :rules="{
          required: true,
          message: '请输入账号名称',
          trigger: 'blur'
        }"
      >
        <a-input placeholder="请输入账号名称" v-model.trim="accountForm.name" />
      </a-form-model-item>
      <a-form-model-item
        label="公司名称"
        prop="company"
        :rules="{
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }"
      >
        <a-input placeholder="请输入公司名称" v-model.trim="accountForm.company" />
      </a-form-model-item>
      <a-form-model-item
        label="联系人"
        prop="contact"
        :rules="{
          required: true,
          message: '请输入联系人',
          trigger: 'blur'
        }"
      >
        <a-input placeholder="请输入联系人" v-model.trim="accountForm.contact" />
      </a-form-model-item>
      <a-form-model-item
        label="手机号码"
        prop="mobile"
        :rules="{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }"
      >
        <a-input placeholder="请输入手机号码" v-model.trim="accountForm.mobile" />
      </a-form-model-item>
      <a-form-model-item
        label="报表时区"
        prop="timeZone"
        :rules="{
          required: true,
          message: '请选择报表时区',
          trigger: 'change'
        }"
      >
        <a-select v-model="accountForm.timeZone" placeholder="请选择报表时区">
          <a-select-option v-for="(item) in timeZoneList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 币种 -->
      <a-form-model-item label="币种" prop="cur">
        <a-radio-group v-model="accountForm.cur" disabled>
          <a-radio :value="0">人民币</a-radio>
          <a-radio :value="1">美元</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
    <div class="btn">
      <a-button type="primary" class="edit-btn" @click="handleSubmit">修改</a-button>
    </div>
  </div>
</template>
<script>
import { getUserInfo, updateAccountInfo } from '@/apiForManage/system'
import { timeZoneList } from '@/data/dataList'
export default {
  name: 'AccountForm',
  data () {
    return {
      accountForm: {},
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 }
      },
      timeZoneList
    }
  },
  created () {
    this.getUserInfoFn()
  },
  methods: {
    // 获取基本信息
    async getUserInfoFn (data) {
      const res = await getUserInfo(data)
      if (res.code === 200 && res.data) {
        const { username, loginId, id, roles, company, contact, mobile, mainApp, roleName, timeZone, cur } = res.data
        this.accountForm = {
          ...this.accountForm,
          id,
          username,
          name: username,
          loginId,
          roles,
          rolesName: roleName,
          // rolesName: roles.length !== 0 && this.formatRolesName(roles[0]),
          company,
          contact,
          mobile,
          mainApp,
          timeZone,
          cur
        }
      }
    },
    // 格式化 角色名称
    formatRolesName (key) {
      switch (key) {
        case 'SUPER_ADMIN':
          return '超级管理员'
        case 'ADMIN':
          return '管理员'
        case 'OPERATOR':
          return '运营'
        case 'VIEWER':
          return '报表'
        case 'AUDITOR':
          return '审核员'
        case 'BUSINESS':
          return '商务'
        case 'MEDIA':
          return '媒体'
        case 'BUSINESS_DIRECTOR':
          return '商务总监'
        case 'OPERATOR_DIRECTOR':
          return '运营总监'
        case 'TECHNOLOGY':
          return '技术'
        case 'FINANCIAL':
          return '财务'
        case 'MANAGEMENT':
          return '管理层'
        case 'ADVANCED_OPERATOR':
          return '高级运营'
        case 'ADVERTISERS':
          return '广告主'
        case 'AGENT':
          return '代理商'
        default:
          return '未知'
      }
    },
    // 修改信息
    async updateAccountInfoFn (data) {
      const res = await updateAccountInfo(data)
      if (res.code === 200) {
        this.$message.success('修改成功')
        this.getUserInfoFn()
      }
    },
    handleSubmit () {
      this.$refs.accountForm.validate((validate) => {
        if (!validate) return false
        this.updateAccountInfoFn(this.accountForm)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.account-container {
  background-color: #fff;
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  display: flex;
  border-radius: @mediumRadius;
  box-shadow: @modelShadow;
  border: 1px solid @modelBorderColor;
  display: flex;
  flex-direction: column;
  .form{
    width: 752px;
    padding: @smallMargin 100px;
  }
}
.btn{
  border-top: 1px solid @compBorderColor;
  display: flex;
  align-items: center;
  height: 80px;
  .edit-btn{
    width: 132px;
    margin-left: 108px;
  }
}
::v-deep.ant-form-item {
  margin-bottom: 20px;
}
</style>
